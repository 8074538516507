/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";

import userAccess from "../../../api/user/userAccess.json";

import { actions as mapActions } from "../../../redux/slices/mapSlice";
import { actions as polygonActions } from "../../../redux/slices/polygonsSlice";
import { selectMode, actions as sidebarActions } from "../../../redux/slices/sidebarSlice";
import { actions as wildfireActions, selectReviews } from "../../../redux/slices/wildfireSlice";
import { selectUserData } from "../../../redux/slices/userSlice";

import ConfirmWildfire from "../Dialogs/ConfirmWildfire";

import { useLocation } from "react-router-dom";

import {
	Check,
	CloudDownloadOutlined,
	ExpandMore,
	FileDownloadOutlined,
	LocalFireDepartment,
	MapOutlined,
	MoreVert,
	Whatshot,
	Forest,
	Park
} from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	DialogContentText,
	IconButton,
	Typography,
	useTheme
} from "@mui/material";
import ReactGA from "react-ga4";
import { List } from "react-virtualized";
import swal from 'sweetalert';
import { downloadKML } from "../../../api/alerts";
import { getZone } from "../../../api/wildfires/utils/getZone";
import noZoneIlustration from "../../../assets/noZonesIlustration.svg";
import RayoVector from '../../../assets/Vector.png';
import { actions as appbar } from "../../../redux/slices/appbarSlice";
import { getDate, getTime } from "../../mapa/leafletUtils/FormatDateTime";
import {
	getCathegoryText,
	getWildfireColor
} from "../../mapa/leafletUtils/CathegoryColor";
import getCenter from "../../utils/getCenter";
import { Button, LoadingButton } from "../../StyledComponents/Button";
import {
	DialogContent, MobileDialog, MobileDialogActions, MobileDialogTitle
} from "../../StyledComponents/Dialog";
import DatesOverlay from "../Misc/DatesOverlay";
import { NotLoggedIn } from "../Tabs/MisZonas";

import TourResumer from "../../utils/TourResumer";
import './zone.scss';

const items = [
	'ultimas-12hs',
	'ultimas-24hs',
	'ultimas-48hs',
	'ultimas-72hs',
	'ultimas-5dias',
	'ultimas-7dias',
	'ultimas-15dias',
	'ultimas-30dias',
];
const values = [12, 24, 48, 72, 120, 168, 360, 720];

function useQuery() {
	const { search } = useLocation();
	//console.log(search)
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Zona(props) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	let query = useQuery();

	const itemsContainerRef = React.useRef();
	const listRef = React.useRef();

	const userData = useSelector(selectUserData).userData;

	const id = query.get("id");
	const fireID = query.get("coords");
	const detectionType = query.get("type");  // "fire" | "lightning" | "deforestation" | "ai";

	const userReviews = useSelector(selectReviews);
	const alerts = useSelector((state) => state.polygons.alerts);
	const rays = useSelector((state) => state.polygons.rays);
	const deforestations = useSelector((state) => state.polygons.deforestations);
	const [selectedAlerts, setSelectedAlerts] = React.useState(alerts);
	const [currentScreen, setCurrentScreen] = React.useState(0); //true es rayos, false es focos
	const polygons = useSelector((state) => state.polygons.polygons);
	const alertsLoading = useSelector((state) => state.polygons.status.alertsLoading);
	const onMove = useSelector((state) => state.map.onMove);
	const wildfireClicked = useSelector((state) => state.map.wildfireClicked);
	const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
	const selectedFires = useSelector((state) => state.sidebar.selectedFires);
	const selectedMode = useSelector((state) => state.sidebar.mode);

	const [confirmWildfireOpen, setConfirmWildfireOpen] = React.useState(false);
	const [selectedWildfire, setSelectedWildfire] = React.useState(null);

	const [polygon, setPolygon] = React.useState(); //Poligono de la zona
	const [zone, setZone] = React.useState(); //Variable que contiene la zona de cada uno de los focos con reverse geocoding
	let center;

	//Paginación
	const [horasFocos, setHorasFocos] = React.useState(24);
	const startDate = new Date();
	startDate.setTime(startDate.getTime() - horasFocos * 60 * 60 * 1000);

	const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);

	React.useEffect(() => {
		if (selectedWildfire !== null) {
			setConfirmWildfireOpen(true);
		} else {
			setConfirmWildfireOpen(false);
		}
	}, [selectedWildfire]);

	const closeDownloadDialog = () => setDownloadDialogOpen(false);
	const openDownloadDialog = () => setDownloadDialogOpen(true);

	const openConfirmeWildfireDialog = (data) => setSelectedWildfire(data);
	const closeReviewDialog = () => setSelectedWildfire(null);
	const handleConfirmSubmit = (date) => {
		if (!selectedWildfire) return

		dispatch(wildfireActions.reviewWildfire({
			...selectedWildfire,
			wildfire_date: date.toISOString().slice(0, 16)
		}));
		setSelectedWildfire(null);
	};

	// Comienzo de funciones de traer alertas
	const getAlerts = (cat) => {
		if (isLoggedIn && userAccess.alerts.includes(userData.userType))
			if (cat === 0) dispatch(polygonActions.getAlerts({ id, backwardHours: horasFocos }));
			else if (cat === 1) dispatch(polygonActions.getRays({ id, backwardHours: horasFocos }));
			else if (cat === 2) dispatch(polygonActions.getDeforestation({ id, backwardHours: horasFocos }));
			else console.warn("No se ha traido ni rayos ni incendios debido a que el número de pantalla es incorrecto.")
	}

	React.useEffect(() => {
		const filters = selectedFires.map((item) => !item);
		if (currentScreen === 0) setSelectedAlerts(selectFireType(alerts, selectedFires));
		else if (currentScreen === 1) setSelectedAlerts(selectFireType(rays, filters));
		else if (currentScreen === 2) setSelectedAlerts(selectFireType(deforestations, selectedFires));
	}, [selectedFires, alerts, rays, deforestations, currentScreen]);

	React.useEffect(() => {
		if (typeof (polygon?.detectRays) === "undefined") return;
		else if (typeof (polygon?.detectWildfires) == "undefined") return;

		if (polygon.detectWildfires || polygon.detectWildfires == null) setCurrentScreen(0);
		else if (polygon.detectRays) setCurrentScreen(1);
		else setCurrentScreen(2);

	}, [polygon?.detectRays, polygon?.detectWildfires])

	// Fin de funciones de traer alertas

	React.useEffect(() => {
		const selectedIndex = values.findIndex((value) => value === horasFocos);
		itemsContainerRef.current?.children[selectedIndex].scrollIntoView({
			inline: "center",
			behavior: "smooth",
		});
	}, [horasFocos]);

	React.useEffect(() => {
		if (isLoggedIn) dispatch(polygonActions.getPolygons());
	}, [isLoggedIn]);

	React.useEffect(() => {
		props.setShowMap(false);
		dispatch(mapActions.setWildfireClicked(null));
	}, []);

	React.useEffect(() => {
		dispatch(
			appbar.setActions(
				<Box display="flex" flexDirection="row" gap="10px">
					<IconButton onClick={() => props.setShowMap((old) => !old)}>
						{props.showMap ? <LocalFireDepartment /> : <MapOutlined />}
					</IconButton>
					{/* <IconButton onClick={() => history.push("/alertas/editar?id=" + id)}>
						<EditOutlined />
					</IconButton> */}
					<IconButton
						id="menuAnchorRef"
						onClick={() => dispatch(appbar.toggleMenu())}
					>
						<MoreVert id="tour-11"/>
					</IconButton>
				</Box>
			)
		);
		dispatch(
			appbar.setMenuItems([
				{
					title: t('descargar-focos-titulo'),
					icon: FileDownloadOutlined,
					action: openDownloadDialog,
				},
				/* {
					title: "Ver instructivo",
					icon: InfoOutlined,
					action: window.abrirInstructivo,
				}, */
			])
		);
	}, [props.showMap, id]);

	React.useEffect(() => {
		getAlerts(currentScreen);
	}, [horasFocos, isLoggedIn, /* Agregado esto */ currentScreen]);

	//Para el comienzo cuando currentScreen arranca en 2
	//React.useEffect(() => {
	//	if (selectedAlerts.length === 0) getAlerts(currentScreen);
	//}, [currentScreen]);

	React.useEffect(() => {
		if (!onMove && polygon && center === undefined)
			dispatch(mapActions.setPolygon([selectedAlerts, polygon]));
	}, [onMove]);

	React.useEffect(() => {
		if (detectionType === "ai" && selectedMode !== 'ia') {
			dispatch(sidebarActions.setMode('ia'));
		}
		if (fireID !== null && selectedAlerts.length > 0) {
			let correctfireID = fireID;
			if (correctfireID.split(" ").length > 1) {
				correctfireID = correctfireID.split(" ");
				correctfireID = correctfireID[0] + "+" + correctfireID[1];
			}
			const matchingAlert = selectedAlerts.find((elem) => elem.date_id === correctfireID);
			if (matchingAlert) {
				dispatch(mapActions.setCoordsMarker([matchingAlert.y, matchingAlert.x]));
				dispatch(mapActions.setCenter([matchingAlert.y, matchingAlert.x, 10]));
				dispatch(mapActions.setWildfireClicked(correctfireID));
				dispatch(mapActions.setPolygon([selectedAlerts, polygon]));
			}
		}
	}, [selectedAlerts, fireID, detectionType, selectedMode]);

	React.useEffect(() => {
		//Se consigue el poligono con el ID del path
		if (polygons.length > 0) {
			polygons.forEach((poly) => {
				if (poly.id === id) setPolygon(poly);
			});
		}
	}, [polygons]);

	React.useEffect(() => {
		dispatch(appbar.setTitle(polygon?.name));
		if (polygon && center === undefined) {
			dispatch(mapActions.setPolygon([selectedAlerts.length ? selectedAlerts : [], polygon]));
			if (fireID === null) {
				center = getCenter(polygon.coords);
				dispatch(mapActions.setCenter([center[0], center[1], 5]));
			}
		}
	}, [selectedAlerts, polygon]);

	//Cuando clickeas un foco en la sidebar
	function handleWildfireClick(el) {
		//Para que se centre el mapa en el foco
		dispatch(mapActions.setCenter([el.y, el.x, 10]));
		dispatch(mapActions.setPolygon([[el], polygon])); // Center en la posición 4 tiene el incendio seleccionado
		dispatch(mapActions.setOnMove(true));
	}

	async function handleExpanded(newExpanded, x, y, index) {
		const coords = await getZone([x, y]);
		setZone(coords);
		//setExpanded(newExpanded === expanded ? false : newExpanded);
		if (newExpanded === wildfireClicked) {
			setExpandedItem(-1);
			dispatch(mapActions.setWildfireClicked(null));
			//dispatch(mapActions.setPolygon([selectedAlerts ? selectedAlerts : [], polygon]));
		} else {
			setExpandedItem(index);
			dispatch(mapActions.setWildfireClicked(newExpanded));
		}
		listRef.current?.recomputeRowHeights(index);
		listRef.current?.forceUpdateGrid();
	}

	function handleScreen(name) {

		const casos = {
			'focos-de-calor': () => setCurrentScreen(0),
			'focos-de-rayos': () => setCurrentScreen(1),
			'focos-de-deforestacion': () => setCurrentScreen(2),
		}

		if (name) casos[name]();
	}

	//Scrollea en la sidebar el item seleccionado
	React.useEffect(() => {
		if (wildfireClicked !== null && wildfireClicked !== undefined) {

			const clickedIndex = selectedAlerts.findIndex(
				(alert) => alert.date_id === wildfireClicked
			);
			setExpandedItem(clickedIndex);
			if (wildfireClicked) {
				listRef.current?.scrollToRow(clickedIndex + 1);
				setTimeout(() => {
					const divIncendio = document.getElementById(wildfireClicked);
					if (divIncendio)
						divIncendio.scrollIntoView({ block: "center", behavior: "smooth" });
				}, 100);
			}
			listRef.current?.recomputeRowHeights();
			listRef.current?.forceUpdateGrid();
		}
	}, [wildfireClicked]);


	const [expandedItem, setExpandedItem] = React.useState(-1);

	function rowHeight({ index }) {
		if (expandedItem === index) return 330;
		return 77;
	}

	const download = (link) => {
		var element = document.createElement("a");
		element.setAttribute("href", link);

		element.style.display = "none";
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	};

	const handleDownloadKML = async () => {
		const path = await downloadKML(polygon.id);

		ReactGA.event({ category: "DownloadKML", action: userData.sub });

		if (path === "no_wildfires") {
			swal(
				t('swal-error-no-dibujaste-poligono-titulo'),
				"",
				"info"
			)
			return;
		}
		const link = "https://kmlrepo.s3.amazonaws.com/" + path;
		download(link);
	};

	const onValidationClick = (element, answer) => {
		const { date_id, sat, conf, x, y } = element;
		const data = {
			wildfire_id: date_id,
			result: answer,
			origin: "user",
			wildfire_date: new Date(date_id.split('_')[0]),
			user_description: `${userData.name} (${userData.email})`,
			source: sat,
			cathegory: conf,
			x: x,
			y: y
		}
		if (answer === 'Y') {
			openConfirmeWildfireDialog(data);
		} else {
			dispatch(wildfireActions.reviewWildfire({
				...data,
				wildfire_date: null
			}));
		}
	}

	function renderRow(params) {
		const { index, key, style } = params;
		const el = selectedAlerts[index];
		const review = userReviews[el.date_id];
		const reviewed = !!review;
		const finishedReview = review && review.result !== 'D';
		const isDeforestation = el.sat === 'gfw-integrated-alerts';
		const isWildfire = el.sat !== 'gfw-integrated-alerts' && el.conf !== 101;
		
		if (!el) return <></>;

		return (
			<Box
				style={style}
				display="flex"
				flexDirection="column"
				sx={{ paddingBottom: "5px" }}
			>
				<Accordion
					className="foco"
					sx={{
						backgroundColor: "#E1E1EC",
						borderRadius: "10px !important",
						padding: "0 5px",
					}}
					elevation={0}
					id={el.date_id}
					expanded={wildfireClicked === el.date_id}
					onChange={() => handleExpanded(el.date_id, el.x, el.y, index)}
					onClick={() => handleWildfireClick(el)}
					key={key}
				>
					<AccordionSummary
						sx={{
							width: "100%",
							overflow: "hidden",
							padding: 0,
							"& .MuiAccordionSummary-content": {
								width: "100%",
								overflow: "hidden",
								justifyContent: "space-between",
								alignItems: "center",
							},
							"& .MuiAccordionSummary-expandIconWrapper": {
								justifyContent: "center",
								width: "34px",
							},
						}}
						expandIcon={<ExpandMore />}
					>
						<Box
							display="flex"
							flexDirection="row"
						>
							<Box
								display="flex"
								flexDirection="row"
								height="3em"
								width="100%"
								alignItems="center"
								gap="5px"
								sx={{ padding: "0 5px" }}
							>
								{
									isDeforestation && (
										<Forest
											sx={{ color: getWildfireColor(el.sat, el.conf) }}
											style={{ width: "auto", height: "80%" }}
										/>
									)
								}
								{
									isWildfire && (
										<Whatshot
											sx={{ color: getWildfireColor(el.sat, el.conf) }}
											style={{ width: "auto", height: "80%" }}
										/>
									)
								}
								<Box display="flex" flexDirection="column">
									<Typography
										whiteSpace="nowrap"
										fontWeight="600"
										fontSize=".95rem"
									>
										{getCathegoryText(el.sat, el.conf)}
									</Typography>
									{wildfireClicked !== el.date_id && (
										<Typography
											whiteSpace="nowrap"
											fontSize=".8rem"
											color="rgba(0,0,0,0.7)"
										>
											{`${getDate(el.date_id)} ${t('a-las')} ${getTime(el.date_id)}`}
										</Typography>
									)}
								</Box>
							</Box>
							{
								(wildfireClicked !== el.date_id && reviewed) && <Button variant="outlined" style={{ color: '#3c3d45', borderColor: '#3c3d45' }}>{t('zona-foco-confirmado')}</Button>
							}
							{wildfireClicked === el.date_id && (
								<Button variant="outlined" className="margin-left-auto map-button" onClick={(e) => { handleWildfireClick(el); props.setShowMap(true); e.stopPropagation() }} startIcon={<MapOutlined />} style={{ marginleft: 'auto !important' }} >{t('ver-en-mapa')}</Button>
							)}
						</Box>
					</AccordionSummary>
					<AccordionDetails>
						{wildfireClicked === el.date_id && (
							<>
								<Box diaplay="flex" flexDirection="column">
									<Typography>
										<b>{t('hora-campo-foco')} </b>
										{getTime(el.date_id)}
									</Typography>
									<Typography>
										<b>{t('fecha-campo-foco')} </b>
										{getDate(el.date_id)}
									</Typography>
									<Typography>
										<b>{t('satelite-campo-foco')} </b> {el.sat}
									</Typography>
									<Typography>
										<b>{t('coords-campo-foco')} </b>
										{`   ${el.y.toFixed(6)}, ${el.x.toFixed(6)} `}
									</Typography>
									<Typography>
										<b>{t('zona-campo-foco')} </b> {zone}
									</Typography>
									{
										reviewed && (
											<>
												<b>{t('zona-confirmada-por-usuario')} </b> {`${getDate(review.review_date)} a las ${getTime(review.review_date)}`}
											</>
										)
									}
									{
										!finishedReview && (
											<Box marginTop="5px">
												<Typography style={{ fontSize: "14px", display: 'table', margin: '0 auto' }}>{ isDeforestation ? t("confirmar-deforestacion") : t("confirmar-incendio") }</Typography>
												<Box display="flex" justifyContent="center" gap="10px">
													<Button onClick={(e) => onValidationClick(el, "Y")} style={{ padding: "0.75rem", minWidth: "0", width: "3rem" }} variant="outlined">{t("confirmar-si")}</Button>
													<Button onClick={(e) => onValidationClick(el, "N")} style={{ padding: "0.75rem", minWidth: "0", width: "3rem" }} variant="outlined">{t("confirmar-no")}</Button>
													<Button onClick={(e) => onValidationClick(el, "D")} style={{ padding: "0.75rem", minWidth: "0", width: "4rem" }} variant="outlined">{t("confirmar-nose")}</Button>
												</Box>
											</Box>
										)
									}
								</Box>
							</>
						)}
					</AccordionDetails>
				</Accordion>
			</Box>
		);
	}

	const FireRayChip = ({ name, item, enabled, id }) => {
		const theme = useTheme();
		const { t } = useTranslation();
		return (
			!enabled ? (
				<Box
					display="flex"
					flexDirection="row"
					gap="5px"
					alignItems="center"
					sx={{
						padding: "4px 6px",
						//padding: "6px 16px 6px 8px",
						/* border: item
							? "1px solid " + theme.palette.primary.light
							: "1px solid #44464E", */
						borderRadius: "6px",
						background: "rgba(73, 69, 79, 0.12)",
						boxSizing: "border-box",
						height: "40px",
						width: "130px",
						fontSize: "14px",
						fontWeight: "500",
						cursor: "default",
					}}
				>
					{name === t('focos-de-calor') && <LocalFireDepartment style={{ width: "15px", height: "15px" }} color="inherit" />}
					{name === t('focos-de-rayos') && <img src={RayoVector} alt="Imagen Rayo" />}
					{name === t('focos-de-deforestacion') &&  <Park style={{ width: "15px", height: "15px" }} color="inherit" />}
					<span>{name}</span>
				</Box>
			) : (
				<Box
					display="flex"
					flexDirection="row"
					gap="5px"
					alignItems="center"
					sx={{
						padding: "4px 6px",
						border: item
							? "1px solid " + theme.palette.primary.light
							: "1px solid #44464E",
						borderRadius: "6px",
						color: item ? "#00164E" : "#44464E",
						bgcolor: item ? "primary.light" : undefined,
						boxSizing: "border-box",
						height: "40px",
						width: "130px",
						fontSize: "14px",
						fontWeight: "500",
						cursor: "pointer",
					}}
					onClick={() => handleScreen(id)}
				>
					{name === t('focos-de-calor') && <LocalFireDepartment style={{ width: "15px", height: "15px" }} color="inherit" />}
					{name === t('focos-de-rayos') && <img src={RayoVector} alt="Imagen Rayo" />}
					{name === t('focos-de-deforestacion') &&  <Park style={{ width: "15px", height: "15px" }} color="inherit" />}
					<span>{name}</span>
				</Box>
			)
		);
	};

	const NoFires = () => {
		const { t } = useTranslation();
		const selectedIndex = values.findIndex((value) => value === horasFocos);
		const str = itemsContainerRef.current?.children[selectedIndex].textContent.toLowerCase().split(" ");
		return (
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="space-between"
				height="100%"
				textAlign="center"
				padding="30px 0"
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					textAlign="center"
					height="100%"
					gap="20px"
					sx={{
						maxWidth: "90%",
						"& img": {
							width: "80%",
							maxHeight: "50%",
						},
					}}
				>
					<img src={noZoneIlustration} alt="ilustración" />
					<Typography variant="span" color="#1B1B1F" fontSize="1rem">
						{`${currentScreen === 1 ? t("no-encontramos-rayos") : t("no-encontramos-focos")} ${str !== undefined && str[1]} ${str !== undefined && str[2]}`}
					</Typography>
				</Box>
			</Box>
		);
	};

	const handleHorasClick = (event, index) => {
		setHorasFocos(values[index]);
	}

	return (
		<>
			{isLoggedIn === false ? <NotLoggedIn /> : (
				<>
					<TourResumer wakeUpSteps={[8]}/>
					<DownloadDialog
						open={downloadDialogOpen}
						close={closeDownloadDialog}
						download={handleDownloadKML}
					/>
					<ConfirmWildfire
						open={confirmWildfireOpen}
						close={closeReviewDialog}
						initialDate={selectedWildfire?.wildfire_date}
						submit={handleConfirmSubmit}
					/>
					<Box display="flex" flexDirection="column">
						<Box
							display="flex"
							flexDirection="row"
							gap="10px"
							id="tour-9"
							sx={{
								backgroundColor: "white",
								padding: "5px 10px 10px 10px",
								width: "100%",
								whiteSpace: "nowrap",
								overflowX: "auto",
								zIndex: 9,
								boxSizing: "border-box",
								"::-webkit-scrollbar": {
									display: "none",
								},
							}}>

							<FireRayChip
								name={t('focos-de-calor')}
								item={currentScreen === 0}
								enabled={polygon?.detectWildfires || polygon?.detectWildfires === null}
								id="focos-de-calor"
							/>
							<FireRayChip
								name={t('focos-de-rayos')}
								item={currentScreen === 1}
								enabled={polygon?.detectRays}
								id="focos-de-rayos"
							/>
							<FireRayChip
								name={t('focos-de-deforestacion')}
								item={currentScreen === 2}
								enabled={polygon?.detectDeforestation}
								id="focos-de-deforestacion"
							/>
						</Box>
						<Box
							ref={itemsContainerRef}
							display="flex"
							flexDirection="row"
							gap="10px"
							id="tour-10"
							sx={{
								backgroundColor: "white",
								padding: "5px 10px 10px 10px",
								width: "100%",
								whiteSpace: "nowrap",
								overflowX: "auto",
								zIndex: 9,
								boxSizing: "border-box",
								"::-webkit-scrollbar": {
									display: "none",
								},
							}}
						>
							{items.filter((_, index) => index < 6 || (index >= 6 && currentScreen === 2)).map((item, index) => {
								return (
									<Item
										key={`tiempo-real-${index}`}
										index={index}
										item={item}
										onClick={event => handleHorasClick(event, index)}
										checked={horasFocos === values[index]}
									/>
								);
							})}
						</Box>
					</Box>
					<DatesOverlay startDate={startDate} endDate={new Date()} screen={currentScreen} />

					{props.showMap ? (
						<></>
					) : 
						(
							alertsLoading ? (
								<Box
									display="flex"
									flexDirection="column"
									alignItems="center"
									justifyContent="space-between"
									height="100%"
									textAlign="center"
									padding="30px 0"
								/>
							) : (
								selectedAlerts.length > 0 ? (
									<FocosList
										listRef={listRef}
										rowHeight={rowHeight}
										renderRow={renderRow}
										alerts={selectedAlerts}
									/>
								) : (
									<NoFires />
								)
							)
						)
					}
				</>
			)}
		</>
	);
}

const FocosList = ({ listRef, rowHeight, renderRow, alerts }) => {

	return (
		<Box
			sx={{ height: "100%", overflow: "hidden", padding: "0 10px" }}
			display="flex"
			flexDirection="column"
			gap="10px"
			id="tour-8"
		>
			{/* <AutoSizer>
				{({ width, height }) => {
					return ( */}
			<List
				id="virtualizedList"
				ref={listRef}
				width={window.visualViewport.width - 5}
				height={window.visualViewport.height - 170}
				rowHeight={rowHeight}
				rowRenderer={renderRow}
				rowCount={alerts?.length}
				style={{ height: "100%", width: "100%" }}
			/>
			{/* );
				}}
			</AutoSizer> */}
		</Box>
	);
};

const DownloadDialog = ({ open, close, download }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState(false);

	const handleDownload = async () => {
		setLoading(true);
		await download();
		setLoading(false);
		close();
	};

	return (
		<MobileDialog open={open} onClose={close}>
			<CloudDownloadOutlined
				color="primary"
				sx={{ alignSelf: "center", height: "30px", width: "30px" }}
			/>
			<MobileDialogTitle>{t('descargar-focos-titulo')}</MobileDialogTitle>
			<DialogContent>
				<DialogContentText>
					{t('descargar-focos-texto')}
				</DialogContentText>
			</DialogContent>
			<MobileDialogActions>
				<Button onClick={close}>{t('boton-cancelar')}</Button>
				<LoadingButton onClick={handleDownload} loading={loading}>
					{t('boton-descargar-focos')}
				</LoadingButton>
			</MobileDialogActions>
		</MobileDialog>
	);
};

const Item = ({ item, checked, onClick }) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<Box
			display="flex"
			flexDirection="row"
			gap="5px"
			alignItems="center"
			sx={{
				padding: "8px 10px",
				border: checked
					? "1px solid " + theme.palette.primary.light
					: "1px solid #44464E",
				borderRadius: "6px",
				color: checked ? "#00164E" : "#44464E",
				bgcolor: checked ? "primary.light" : undefined,
				boxSizing: "border-box",
				height: "40px",
				fontWeight: "500",
			}}
			onClick={onClick}
		>
			{checked ? <Check color="inherit" /> : <></>}
			<span>{t(item)}</span>
		</Box>
	);
};

function selectFireType(wildfires, selectedfires) {
	let tempIncendios = wildfires;
	for (let i = 0; i <= 7; i++) {
		if (!selectedfires[i]) {
			tempIncendios = tempIncendios.filter((incendio) => {
				if (incendio.sat === "gfw-integrated-alerts") {
					return i !== 8;
				} else if (incendio.sat.indexOf("sr-goes") !== -1) {
					return i !== 7;
				} else if (incendio.sat === "MODIS Terra" || incendio.sat === "MODIS Aqua") {
					if (incendio.conf >= 0 && incendio.conf <= 35) return i !== 4;
					if (incendio.conf > 35 && incendio.conf <= 65) return i !== 3;
					if (incendio.conf > 65 && incendio.conf <= 100) return i !== 2;
				} else {
					if (incendio.conf === 0 || incendio.conf === 10 || incendio.conf === 30) return i !== 0;
					if (incendio.conf === 11 || incendio.conf === 31) return i !== 1;
					if (incendio.conf === 12 || incendio.conf === 32) return i !== 2;
					if (incendio.conf === 13 || incendio.conf === 33 || incendio.conf === 90) return i !== 3;
					if (incendio.conf === 14 || incendio.conf === 34 || incendio.conf === 50) return i !== 4;
					if (incendio.conf === 15 || incendio.conf === 35 || incendio.conf === 20) return i !== 5;
					if (incendio.conf === 101) return i !== 6;
				}
			});
		}
	}
	return tempIncendios;
}
