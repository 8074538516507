import  { useTranslation } from 'react-i18next'
import {
	ListItemButton,
	FormControlLabel,
	Typography,
	Box,
	Radio,
	RadioGroup,
	FormControl,
	ListItem,
} from "@mui/material";
import React from "react";
import PanelHeader from "../Panel/PanelHeader";
import { useDispatch, useSelector } from "react-redux";
import { actions as wildfireActions } from "../../../redux/slices/wildfireSlice";
import {
	actions as sidebarActions,
	selectCurrentMode,
} from "../../../redux/slices/sidebarSlice";
import DatesOverlay from "../Misc/DatesOverlay";

const actions = {
	1: wildfireActions.getLastTenMinutes,
	2: wildfireActions.getLastHour,
	3: wildfireActions.getLastThreeHours
};
const times = {
	1: 20,
	2: 60,
	3: 180
};

export default function TiempoReal() {
	const { t } = useTranslation()
	const dispatch = useDispatch();
	const [mode, setMode] = React.useState(
		useSelector(selectCurrentMode).toString()
	);
	const diff = times[mode];
	const startDate = new Date();
	startDate.setTime(startDate.getTime() - diff * 60 * 1000);

	const [checked, setChecked] = React.useState(
		useSelector((state) => state.sidebar.showRays)
	);

	function handleChangeMode(event) {
		if (event.target) {
			setMode(event.target.value);
			if (event.target.value !== "5") dispatch(actions[event.target.value]());
			dispatch(sidebarActions.setCurrentMode(event.target.value));
			if (event.target.value !== 1 && checked) handleChangeCheckbox();
		} else {
			setMode(event);
			if (event !== "5") dispatch(actions[event]());
			dispatch(sidebarActions.setCurrentMode(event));
			if (event !== 1 && checked) handleChangeCheckbox();
		}
	}

	function handleChangeCheckbox() {
		dispatch(sidebarActions.toggleShowRays());
		setChecked(!checked);
	}

	const items = [t('ultimos-20min'), t('ultima-hora'), t('ultimas-3horas')];

	return (
		<>
			<DatesOverlay startDate={startDate} endDate={new Date()} />
			<Box sx={{ height: "100%" }} display="flex" flexDirection="column">
				<PanelHeader title={t('tiempo-real')} />
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
					gap="10px"
					sx={{ marginTop: "20px", height: "100%" }}
				>
					<Box  display="flex" flexDirection="column" gap="20px">
						<Typography className="rj1" variant="span" color="#44464E" fontSize=".9rem">
							{t("typography-tiempo-real")}
						</Typography>

						<FormControl variant="standard">
							<RadioGroup
								name="tiempo_real"
								value={mode}
								onChange={handleChangeMode}
								sx={{ gap: "10px" }}
							>
								{items.map((item, index) => {
									return (
										<ListItem
											key={`tiempo-real-radio-${index}`}
											disablePadding
											sx={{
												backgroundColor:
													(index + 1).toString() === mode
														? "#DAE1FF"
														: undefined,
												borderRadius: "100px",
											}}
										>
											<ListItemButton
												onClick={() => handleChangeMode((index + 1).toString())}
												sx={{
													padding: "5px 20px",
													"& .MuiTouchRipple-root, &": {
														borderRadius: "100px",
													},
												}}
											>
												<FormControlLabel
													value={index + 1}
													control={<Radio />}
													label={item}
													//className="rj2"
												/>
											</ListItemButton>
										</ListItem>
									);
								})}
							</RadioGroup>
						</FormControl>
					</Box>

					{/* <LinkButton
						href="https://sof-wildfire-bucket.s3.amazonaws.com/Instructivo+SoF.pdf"
						target="_blank"
					>
						Ver instructivo
					</LinkButton> */}
				</Box>
			</Box>
		</>
	);
}
