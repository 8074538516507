import { useEffect, useState } from 'react';
import { LayersList } from '@deck.gl/core';
import { ControlPosition, APIProvider, Map, Marker, MapMouseEvent } from '@vis.gl/react-google-maps';
import { MapProps } from '../MapTypes';
import BaseMapDeckGL from './BaseMapDeckGL';
import { wildfireScatteredPlot } from './layers/WildfireMarkers';
import { InfoWindow } from '@vis.gl/react-google-maps';
import { wildfirePolygon } from './layers/WildfirePolygon';
import { WildfirePopup } from './GoogleMapsPopup';
import { MapWildfire } from '../../../redux/slices/types/wildfireTypes';
import { CustomControl } from './controls/CustomControl';
import CenterController from './controls/CustomAutoCenterController';
import { cameraMarkers } from './layers/CameraMarkers';
import { selectWildfireClicked } from '../../../redux/slices/mapSlice';
import { useAppSelector } from '../../../hooks';


export default function GoogleMaps(props: MapProps) {
  
  const mapTypeId = props.layer === 'satellite-v9' ? 'satellite' : 'roadmap';
  const [selectedWildfire, setSelectedWildfire] = useState<MapWildfire | null>(null);
  const [userLocation, setUserLocation] = useState<google.maps.LatLngLiteral | null>(null);
  const wildfireClicked = useAppSelector(selectWildfireClicked);

  useEffect(() => {
    if (wildfireClicked) handleClickWildfire(wildfireClicked);
  }, [wildfireClicked]);

  const handleClickWildfire = (wildfireId: string) => {
    const wildfire = props.wildfires.find((w: MapWildfire) => w.id === wildfireId);
    if (wildfire) setSelectedWildfire(wildfire);
    props.onClickWildfire(wildfireId);
  };

  const layers: LayersList = [
    wildfireScatteredPlot(props.wildfires, props.wildfireType, handleClickWildfire),
    wildfirePolygon(props.polygon, props.onClickPoly),
    cameraMarkers(props.cameras, props.onClickCamera)
  ]

  const closePopup = (event?: MapMouseEvent) => {
    if (selectedWildfire) setSelectedWildfire(null);
    if (event?.detail.placeId) event.stop();
  };

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevents the map click from being triggered
  };


  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_TOKEN || ''}>
      <Map
        mapId={'3202c5a6e078f70b'}
        style={{ width: '100%', height: '100%' }}
        defaultCenter={{ 'lat': props.defaultCenter[0], 'lng': props.defaultCenter[1] }}
        defaultZoom={props.defaultZoom}
        mapTypeId={mapTypeId}
        disableDefaultUI={true}
        controlled={false}
        reuseMaps={true}
        onClick={closePopup}
      >
        <BaseMapDeckGL layers={layers} />
        
        <CustomControl
          controlPosition={ControlPosition.TOP_RIGHT}
          onUserLocationChange={(location: google.maps.LatLngLiteral) => setUserLocation(location)}
        />

        <CenterController center={props.center} />

        {userLocation && (<Marker position={userLocation}></Marker>)}

        {
          selectedWildfire && (
            <InfoWindow
              onClose={() => closePopup()}
              position={{ lat: selectedWildfire.y, lng: selectedWildfire.x }}
              maxWidth={350}>
              <div onClick={stopPropagation}>
                <WildfirePopup
                  wildfire={selectedWildfire}
                  reviewed={false}
                  wildfireType={props.wildfireType}
                  loggedIn={true}
                />
              </div>
            </InfoWindow>
          )
        }
      </Map>
    </APIProvider>
  )
}